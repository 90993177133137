export enum SIGNALR_TYPES {
  'uploadPortalCustomReportDocumentStatusUpdated' = 'uploadPortalCustomReportDocumentStatusUpdated',
  'envelopeCompleted' = 'envelopeCompleted'
}

export enum SIGNALR_TARGETS {
  'notifySuccess' = 'notifySuccess',
  'envelopeCompleted' = 'envelopeCompleted',
  'notifyError' = 'notifyError',
  'uploadPortalCustomReportDocumentStatusUpdated' = 'uploadPortalCustomReportDocumentStatusUpdated'
}

export enum SIGNALR_CHANNELS {
  'signalRNotifySuccess' = 'signalRNotifySuccess',
  'signalRNotifyError' = 'signalRNotifyError'
}
