import { alpha, createTheme, theme as kycTheme, lighten } from '@kyc-ui/react'
import { SYSTEM_FONTS } from 'src/constants/app'
import 'src/theming/agpgroup/fonts/style.scss'
import Logo from 'src/theming/agpgroup/images/logo.svg'
import EntityUpload from 'src/theming/agpgroup/images/entity-upload.gif'
import EntityUploaded from 'src/theming/agpgroup/images/entity-uploaded.png'
import { ReactComponent as LogoIcon } from 'src/theming/agpgroup/images/logo-icon.svg'
import Location from 'src/theming/agpgroup/images/home-location.png'
import Login from 'src/theming/agpgroup/images/login.png'
import Agreement from 'src/theming/agpgroup/images/agreement.png'
import BeforeWeBegin from 'src/theming/agpgroup/images/before-we-begin.png'
import Loading from 'src/theming/agpgroup/images/loading.gif'

const fonts = {
  system: `Poppins,${SYSTEM_FONTS}`,
  body: `Calibri,${SYSTEM_FONTS}`,
  title: `Calibri, ${SYSTEM_FONTS}`
}

const extraColors = {
  Primary: '#1A988B',
  GoldFusion: '#F4A089',

  Timberwolf: '#D9D4D0',
  DarkBlue: '#1C355E',
  DarkBlue50: '#8895A9',
  LightGrey: '#F8F8F8',
  Background: '#EAECEE',
  InputLabel: '#73829A'
}

const palette = {
  ...kycTheme.palette,
  primary: {
    ...kycTheme.palette.primary,
    main: extraColors.Primary,
    light: '#1fbfaf',
    dark: '#0F7066'
  },
  text: {
    ...kycTheme.palette.text,
    primary: '#1E3D51',
    secondary: '#737491'
  }
}

const themeBase = createTheme({
  typography: {
    fontFamily: fonts.body,
    h5: {
      fontFamily: fonts.title,
      fontWeight: '700!important',
      fontSize: '23px',
      lineHeight: '30px',
      letterSpacing: '0.3px'
    },
    h6: {
      fontFamily: fonts.title,
      fontWeight: '700!important',
      fontSize: '20px',
      lineHeight: '26.4px',
      letterSpacing: '0.3px'
    },
    body1: {
      fontWeight: '400!important',
      fontSize: '16px',
      lineHeight: '21.5px',
      letterSpacing: '0.3px'
    },
    button: {
      fontFamily: fonts.body,
      fontSize: '16px!important',
      fontWeight: '700!important',
      lineHeight: '20px',
      letterSpacing: '0.3px',
      '& .MuiButton-sizeSmall': {
        fontSize: '16px!important',
        lineHeight: '24px',
        letterSpacing: '1.25px'
      },
      '& .MuiButton-sizeLarge': {
        fontSize: '16px!important',
        lineHeight: '26px',
        letterSpacing: '1.25px'
      }
    }
  }
})

const theme = {
  typography: themeBase.typography,
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontFamily: fonts.system,
          fontSize: '12px',
          fontWeight: '400'
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '& .MuiAvatar-root': {
            color: extraColors.Primary
          },
          '& .deleteIcon': {
            color: palette.action.active
          }
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: lighten(extraColors.GoldFusion, 0.6),
          '& .MuiLinearProgress-bar': {
            backgroundColor: extraColors.GoldFusion
          }
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#000',
          '&.agreementTypography a': {
            color: extraColors.GoldFusion
          },
          '&.agreementTitle': {
            color: palette.text.primary
          },
          '&.MuiTypography-overline': {
            color: palette.primary.main
          },
          '&.MuiTypography-overline.progressText': {
            color: palette.secondary.dark
          },
          '&.serviceRequiresText': {
            color: palette.text.primary
          },
          '&.MuiListItemText-secondary': {
            color: alpha(palette.text.secondary, 0.87),
            fontSize: '15px',
            fontWeight: '400!important',
            lineHeight: '150%'
          },
          '&.locationText': {
            color: palette.text.primary
          },
          '&.docText': {
            color: palette.text.primary
          }
        }
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          fontSize: '16px!important',
          fontWeight: '700!important',
          lineHeight: '20px',
          letterSpacing: '0.3px',
          '&.topNavBtn': {
            color: palette.text.primary
          },
          '&.topNavBtn: hover': {
            background: '#E5E6E6'
          },
          '&.infoIcon, .deleteIcon, .cancelIcon': {
            color: palette.action.active
          },
          '&.uploadFileCustom': {
            color: palette.action.active
          },
          '&.MuiButton-sizeLarge': {
            fontSize: '18px',
            lineHeight: '27px',
            letterSpacing: '1.25px'
          }
        }
      }
    }
  },
  palette,
  images: {
    upload: EntityUpload,
    completed: EntityUploaded,
    logo: Logo,
    logoIcon: LogoIcon,
    location: Location,
    login: Login,
    agreement: Agreement,
    loading: Loading,
    individualBeforeWeBegin: BeforeWeBegin
  },
  colorName: {
    button: 'primary',
    linearProgress: 'primary',
    checkbox: 'primary',
    stepColor: 'primary.dark',
    cardBorderColor: 'none',
    addButton: 'primary',
    switch: 'primary',
    pagination: 'primary',
    progressButton: 'secondary'
  },
  colorString: {
    checkbox: palette.primary.main,
    estimatedTime: '#EC6278'
  },
  sizes: {
    button: 'large',
    beforeWeBeginMaxWidth: '500px'
  },
  sx: {
    agreementDialogCheckbox: {
      alignItems: 'flex-start'
    },
    agreementDevicePrompt: {
      textAlign: 'left',
      px: 0
    },
    uploadingCard: {
      backgroundColor: 'transparent',
      height: 'auto',
      img: {
        height: '400px'
      }
    },
    uploadedCard: {
      backgroundColor: 'transparent',
      height: 'auto',
      '>div': {
        display: 'flex',
        borderRadius: '16px',
        backgroundColor: 'white'
      },
      img: {
        height: '400px'
      }
    },
    completedCard: {
      width: 'auto',
      mx: 'auto',
      mb: '24px!important',
      '*': {
        padding: '0!important',
        mb: '0!important',
        display: 'flex'
      },
      img: {
        maxHeight: '358px'
      },
      '.completedImage': {
        padding: '24px!important'
      }
    },
    completedCard2: {
      '*': {
        padding: '0!important',
        mb: '0!important'
      },
      p: {
        padding: '16px!important'
      }
    },
    location: {
      img: {
        maxHeight: '320px'
      }
    }
  },
  others: {
    customize: {
      background: {
        background: 'rgba(223, 235, 234, 1)'
      },
      loginBackground: {
        background: 'rgba(223, 235, 234, 1)'
      }
    }
  },
  consts: {
    isEqual: true,
    isAgpGroup: true
  }
}
export default theme
